<template>
    <div class="player-container">
        <div class="player" v-if="audio">
            <div class="card-body">
                <div class="player-track-meta">
                    <div style="width: 100%;">
                        <h3 class="rows-2" @click="goToAudio()">
                            {{ audio.name }}
                        </h3>
                    </div>
                    <div style="padding-left: 6px;">
                        <fai class="sidebar-toggle cursor-pointer" :icon="['fas', 'xmark']" @click="closePlayer()"></fai>
                    </div>
                </div>
                <div class="player-controls">
                    <button class="player-play-btn large-controls"
                            role="button"
                            :aria-label="isPlaying ? 'Pause' : 'Play'"
                            data-playing="false"
                            @click="toggleAudio()">

                        <div v-if="!isAudioLoaded">
                            <div class="dot-flashing"></div>
                        </div>

                        <div v-show="isAudioLoaded && !isPlaying">
                            <img src="/icons/play.svg" alt="play icon" />
                        </div>

                        <div v-show="isAudioLoaded && isPlaying">
                            <img src="/icons/pause.svg" alt="pause icon" />
                        </div>
                    </button>
                    <div class="player-loop-btn large-controls" title="Play on loop" @click="togglePlayOnLoop">
                        <img :src='playOnLoop ? "/icons/replay-selected.svg" : "/icons/replay.svg"' alt="play on loop icon" />
                    </div>
                    <div class="player-timeline">
                        <span class="player-time player-time-current">{{ elapsedTime() }}</span>
                        <div class="player-progress-container" @click="scrub">
                            <div class="player-progress" ref="progress">
                                <div class="player-progress-filled" :style="{'flex-basis' : ((playbackTime / audioDuration) * 100) + '%'}"></div>
                            </div>
                        </div>
                        <span class="player-time player-time-duration">{{ totalTime() }}</span>
                    </div>
                    <div class="player-volume-container large-controls">
                        <input type="range" id="volume" min="0" max="1" step="0.01" class="player-volume" v-model="player.audioVolume" />
                    </div>
                </div>

                <div class="small-controls">
                    <button class="player-play-btn"
                            role="button"
                            :aria-label="isPlaying ? 'Pause' : 'Play'"
                            data-playing="false"
                            @click="toggleAudio()">

                        <div v-if="!isAudioLoaded">
                            <div class="dot-flashing"></div>
                        </div>

                        <div v-show="isAudioLoaded && !isPlaying">
                            <img src="/icons/play.svg" alt="play icon" />
                        </div>

                        <div v-show="isAudioLoaded && isPlaying">
                            <img src="/icons/pause.svg" alt="pause icon" />
                        </div>
                    </button>

                    <div class="player-volume-container">
                        <input type="range" id="volume" min="0" max="1" step="0.01" class="player-volume" v-model="player.audioVolume" />
                    </div>

                    <div class="player-loop-btn" title="Play on loop" @click="togglePlayOnLoop">
                        <img :src='playOnLoop ? "/icons/replay-selected.svg" : "/icons/replay.svg"' alt="play on loop icon" />
                    </div>
                </div>
            </div>
        </div>
    </div>        
</template>

<script>
    import { secondsToTimeLabel } from './../utils.js'
    import requests from './../api/requests.js'

    export default {
        name: 'AudioPlayerSmall',
        props: {

        },
        data() {
            return {
                playOnLoop: false,
                playbackTime: 0,
                audioDuration: 100,
                file: null,
                listenTime: 0,
                lastListenSecond: -1,
                listenSecondsBuffer: [],
                listenSecondsSendInterval: 10,
                moreOptionsOpen: false
            }
        },
        computed: {
            isLogged() {
                return this.$store.getters.userData != null
            },
            isLoggedUsersAudio() {
                if (this.$store.getters.userData == null) {
                    return false
                }

                return this.$store.getters.userData.userProfileId == this.profile.id
            },
            isAdmin() {
                if (this.$store.getters.userData) {
                    return this.$store.getters.userData.user.isAdmin
                }

                return false
            },
            showReplayButton() {
                return this.playbackTime >= this.audioDuration
            },
            player() {
                return this.$store.getters.player
            },
            playerElement() {
                return document.getElementById('player')
            },
            audio() {
                return this.$store.getters.player.currentAudio.audio
            },
            isPlaying() {
                return this.player.isPlaying
            },
            isAudioLoaded() {
                return this.player.isAudioLoaded
            }
        },
        methods: {
            initSlider() {
                if (this.playerElement) {
                    this.audioDuration = Math.round(this.playerElement.duration)
                }
            },
            convertTime(seconds) {
                const format = val => `0${Math.floor(val)}`.slice(-2)
                let hours = seconds / 60 / 60
                let minutes = (seconds % 3600) / 60
                return hours >= 1
                    ? [hours, minutes, seconds % 60].map(format).join(":")
                    : [minutes, seconds % 60].map(format).join(":")
            },
            totalTime() {
                let audio = this.playerElement
                if (audio && !isNaN(audio.duration)) {
                    let seconds = audio.duration
                    return this.convertTime(seconds)
                } else {
                    return '00:00'
                }
            },
            elapsedTime() {
                let audio = this.playerElement
                if (audio) {
                    let seconds = audio.currentTime
                    return this.convertTime(seconds)
                } else {
                    return '00:00';
                }
            },
            async playbackListener(e) {
                let audio = this.playerElement
                this.listenTime += Math.abs(this.playbackTime - audio.currentTime)
                this.playbackTime = audio.currentTime

                const listenSecond = Math.floor(this.playbackTime)
                if (this.listenSecondsBuffer.length <= 0 || this.lastListenSecond != listenSecond) {
                    this.listenSecondsBuffer.push(listenSecond)
                    this.lastListenSecond = listenSecond

                    await this.trySendListenSeconds()
                }

                audio.addEventListener("ended", this.endListener)
                audio.addEventListener("pause", this.pauseListener)
            },
            pauseListener() {
                this.player.isPlaying = false
                this.listenerActive = false
                this.cleanupListeners()
            },
            endListener() {
                this.player.isPlaying = false
                this.listenerActive = false
                this.cleanupListeners()
            },
            cleanupListeners() {
                let audio = this.playerElement
                audio.removeEventListener("timeupdate", this.playbackListener)
                audio.removeEventListener("ended", this.endListener)
                audio.removeEventListener("pause", this.pauseListener)
            },
            toggleAudio() {
                let audio = this.playerElement;
                if (audio.paused) {
                    audio.play()
                    this.player.isPlaying = true
                } else {
                    audio.pause()
                    this.player.isPlaying = false
                }
            },
            scrub(event) {
                let audio = this.playerElement

                audio.currentTime = this.audioDuration * (event.offsetX / this.$refs.progress.offsetWidth)
                this.playbackTime = audio.currentTime
            },  
            async trySendListenSeconds(forceSend) {
                if (!forceSend
                    && this.listenSecondsBuffer.length < this.listenSecondsSendInterval + 1
                    && this.playbackTime < this.audioDuration - 1) {
                    return
                }

                const secondsToSend = this.listenSecondsBuffer.splice(0, Math.min(this.listenSecondsBuffer.length, this.listenSecondsSendInterval))

                await requests.Statistics.ListenSession.ListenToAudio(this.audio.id, secondsToSend)
            },
            async refreshUserHistory() {
                if (this.isLogged) {
                    const historyResult = await requests.UserContent.UserHistory.GetUserHistoryById(this.$store.getters.userData.user.id)

                    if (historyResult.isSuccess) {
                        this.$store.commit("setUserHistory", historyResult.userHistory)
                    }
                }
            },
            goToAudio() {
                this.$router.push('/audio/' + this.audio.id)
            },
            closePlayer() {
                this.playerElement.pause()
                this.player.clearAudio()
            },
            togglePlayOnLoop() {
                this.playOnLoop = !this.playOnLoop

                this.playerElement.loop = this.playOnLoop
            }
        },
        async mounted() {
            requests.initialize(this.$store)

            let audio = this.playerElement

            if (audio == null) {
                return
            }

            audio.addEventListener(
                "loadedmetadata",
                function (e) {
                    this.initSlider()
                }.bind(this)
            )

            audio.addEventListener(
                "canplay",
                function (e) {
                    this.audioLoaded = true
                }.bind(this)
            )

            this.$watch("player.isPlaying", () => {
                if (this.player.isPlaying) {
                    let audio = this.playerElement
                    this.initSlider()

                    if (!this.listenerActive) {
                        this.listenerActive = true
                        audio.addEventListener("timeupdate", this.playbackListener)
                    }
                }
            })

            this.$watch("player.audioVolume", () => {
                let audio = this.playerElement

                audio.volume = this.player.audioVolume
            })

            this.$watch("listenTime", async () => {
                if (!this.player.isListenSent && this.listenTime > 1) {
                    this.player.isListenSent = true
                    var result = await requests.UserContent.Audio.ListenAudio(this.audio.id)

                    if (result.isSuccess && result.isListenAdded) {
                        this.audio.listenCount += 1
                        await this.refreshUserHistory()
                    }
                }
            })

            if ('mediaSession' in navigator) {
                navigator.mediaSession.setActionHandler('play', this.toggleAudio)

                navigator.mediaSession.setActionHandler('pause', this.toggleAudio)

                let audio = this.playerElement
                navigator.mediaSession.setActionHandler('seekbackward', function () {
                    audio.currentTime -= 10
                })
                navigator.mediaSession.setActionHandler('seekforward', function () {
                    audio.currentTime += 10
                })
                navigator.mediaSession.setActionHandler('seekto', function (event) {
                    if (event.fastSeek && 'fastSeek' in audio) {
                        audio.fastSeek(event.seekTime)
                    } else {
                        audio.currentTime = event.seekTime
                    }
                })
            }
        },
        async beforeUnmount() {
            this.cleanupListeners()
            await this.trySendListenSeconds(true)
        }
    }
</script>

<style scoped>
    .player-track-meta {
        display: flex;
    }

    .player-track-meta h3 {
        margin: 0;
        color: var(--primary-color);
        font-size: 16px;
        line-height: 22px;
        font-weight: 600;
        padding-bottom: 0.5rem;
        color: var(--primary-color);
        height: 44px;
    }

    .player-track-meta span {
        font-size: 16px;
        font-weight: 400;
        padding: 0 2px;
        position: relative;
        top: 1px;
        color: #ADADCB;
    }

    .cursor-pointer {
        cursor: pointer;
    }

    .player-controls {
        display: flex;
        align-items: center;
    }

    .player-play-btn {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
    }

    .player-loop-btn {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 8px;
    }

    .icon-container {
        fill: transparent;
        stroke: none;
    }

    .player-play-btn:hover {
        fill: #ba54f5;
    }

    .player-play-btn svg {
        color: var(--primary-color);
        position: relative;
        left: 0.5px;
        width: 36px;
        height: 36px;
        display: block;
    }

    .player-play-btn:hover svg {
        color: var(--primary-color);
    }

    .player-timeline {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: space-between;
        padding-left: 10px;
        color: white;
    }

    .player-progress {
        display: flex;
        position: relative;
        height: 6px;
        background: #46424F;
        border-radius: 25px;
        margin: 0 5px;
        flex: 10;
        flex-basis: 100%;
        overflow: hidden;
    }

    .player-progress-container {
        padding-top: 1rem;
        padding-bottom: 1rem;
        width: 100%;
    }

    .player-progress-filled {
        height: 6px;
        background: var(--primary-color);
        flex: 0;
        flex-basis: 0%;
        border-radius: 25px;
    }

    .player-time {
        padding: 2px 5px;
    }

    .player-volume-container {
        width: 30%;
    }

        .player-volume-container.large-controls {
            width: 15%;
        }

    .player-volume {
        height: 28px;
        -webkit-appearance: none;
        margin: 10px 0;
        width: 100%;
        background: transparent;
    }

        .player-volume:focus {
            outline: none;
        }

        .player-volume::-webkit-slider-runnable-track {
            width: 100%;
            height: 6px;
            cursor: pointer;
            animate: 0.2s;
            background: var(--primary-color);
            border-radius: 10px;
        }

        .player-volume::-webkit-slider-thumb {
            height: 16px;
            width: 16px;
            border-radius: 100px;
            border: none;
            background: var(--primary-color);
            cursor: pointer;
            -webkit-appearance: none;
            margin-top: -4px;
        }

        .player-volume:focus::-webkit-slider-runnable-track {
            background: var(--primary-color);
        }

        .player-volume::-moz-range-track {
            width: 100%;
            height: 6px;
            cursor: pointer;
            animate: 0.2s;
            background: var(--primary-color);
            border-radius: 10px;
        }

        .player-volume::-moz-range-thumb {
            height: 16px;
            width: 16px;
            border-radius: 100px;
            border: none;
            background: var(--primary-color);
            cursor: pointer;
            margin-top: -4px;
        }

        .player-volume::-ms-track {
            width: 100%;
            height: 6px;
            cursor: pointer;
            animate: 0.2s;
            background: var(--primary-color);
            border-radius: 10px;
        }

        .player-volume::-ms-fill-lower {
            background: var(--primary-color);
            border-radius: 10px;
        }

        .player-volume::-ms-fill-upper {
            background: var(--primary-color);
            border-radius: 10px;
        }

        .player-volume::-ms-thumb {
            margin-top: 1px;
            height: 15px;
            width: 15px;
            border-radius: 5px;
            border: none;
            background: var(--primary-color);
            cursor: pointer;
        }

        .player-volume:focus::-ms-fill-lower {
            background: #38bdf8;
        }

        .player-volume:focus::-ms-fill-upper {
            background: #38bdf8;
        }

    .small-controls {
        display: flex;
        flex-flow: row;
        justify-content: space-evenly;
        align-items: center;
    }

    @media screen and (min-width: 40rem) {
        .small-controls {
            display: none;
        }
    }

    @media screen and (max-width: 40rem) {
        .large-controls {
            display: none;
        }
    }

    .more-options {
        position: absolute;
        top: 16px;
        right: 16px;
        display: flex;
        flex-flow: row;
        column-gap: 0.5rem;
    }

        .more-options button {
            min-width: 2.5rem;
            border: 1px gray solid !important;
        }

    .player-container {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        display: flex;
    }

    .player {
        width: 100%;
        background-color: var(--secondary-background);
        padding: 12px 16px 12px 16px;
        margin-left: 6px;
        margin-right: 6px;
        margin-bottom: 6px;
        border-radius: 6px;
        border: 1px solid var(--tertiary-background);
    }

    @media screen and (min-width: 40rem) {
        .player {
            margin-left: 226px;
        }
    }

    .rows-2 {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: pointer;
    }
</style>